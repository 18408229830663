import React from "react"
import { Link, graphql } from "gatsby"

import { DiscussionEmbed } from 'disqus-react'
import _ from 'lodash'

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/article-detail.css"

// To convert string into Title Case
function titleCase(str){
	str = str.toLowerCase().split(' ');
	let final = [ ];
	for(let  word of str){
		 final.push(word.charAt(0).toUpperCase()+ word.slice(1));
	}
  return final.join(' ')
}

const ArticleDetail = ({ props, data }) => {
    const title = data.markdownRemark.frontmatter.title
    const publish = data.markdownRemark.frontmatter.publish
    const update = data.markdownRemark.frontmatter.update
    const category = data.markdownRemark.frontmatter.category
    const keywords = data.markdownRemark.frontmatter.keywords
    const path = data.markdownRemark.frontmatter.path
    const disqusIdentifier = data.markdownRemark.frontmatter.disqusIdentifier
    const html = data.markdownRemark.html
    
    /* disqus configuration */
    const disqusShortName = 'akinul-dot-com'
    const disqusConfig = {
        identifier: disqusIdentifier,
        title: title,
        url: 'https://akinul.com'+path,
    }

    return (
        <Layout>
            <SEO title={ title } keywords={ keywords } />   
            <div id="articleDetailContainer">
                <div id="articleDetailTitle"> { title } </div>
                <div id="articleDetailDateCategory">
                    <div>
                        { publish }
                        <span style={{ color: `dodgerblue`}}>&nbsp;|&nbsp;</span>
                        Updated: { update }
                    </div>
                    <div>
                        <small># </small>
                        {category.map((eachCategory, index) => 
                            <>
                                <Link id="linkArticleDetailCategory"
                                    key={ `/${_.kebabCase(eachCategory)}/` }                                       
                                    to={ `/${_.kebabCase(eachCategory)}/` }
                                >{ titleCase(eachCategory) }
                                </Link>
                                <small><span>{category[index+1] ? ` | ` : ``}</span></small>
                            </>
                        )}
                    </div>
                </div><hr/>
                <div id="articleDetailBody">
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
            </div>
            <div id="articleDetailCommentContainer">
                <div id="articleDetailCommentBanner">
                    <br/><span className="mdTextHeading">Comments</span>
                    <p>You are welcome to write comments, suggestions, corrections,
                    or any queries related to the article.
                    Your comments may take some time to be appeared.
                    Please be aware that any irrelevant comments will be deleted.
                    Thanks for your understanding, and your 
                    respectful &amp; relevant comments!</p>
                </div>
                <div id="articleDetailCommentDisqus">
                    <DiscussionEmbed shortname={disqusShortName} config={disqusConfig} />
                </div>
            </div>
        </Layout>
    )
}

export const articleQuery = graphql`
    query EachArticleByPathQuery ($pathSlug: String!) {
        markdownRemark(frontmatter: { path: { eq: $pathSlug }}) {
            html
            frontmatter {
                title
                publish(formatString: "MMM DD, YYYY")
                update(formatString: "MMM DD, YYYY")
                category
                keywords
                path
                disqusIdentifier
            }
        }
    }
`
export default ArticleDetail

// Ignore following words from checking by Code Spell Checker
// cSpell:ignore akinul jony disqus frontmatter dodgerblue shortname 